import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class TokenProviderService {
  private tokenKey = "userToken";

  private userToken: any;

  public get token() {
    if (this.userToken) {
      return this.userToken;
    }
    this.userToken = localStorage.getItem(this.tokenKey);
    return this.userToken;
  }

  public set token(value: string) {
    localStorage.setItem(this.tokenKey, value);
    this.userToken = value;
  }

  remove(): void {
    localStorage.removeItem(this.tokenKey);
    this.userToken = null;
  }
}
