import {enableProdMode} from "@angular/core";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular";
import {AppModule} from "./app/app.module";
import {environment} from "./environments/environment";
import {Integrations} from "@sentry/tracing";

Sentry.init({
  dsn: "https://f9074fdaf9f34771b317a7bd0a46bcd4@o1084967.ingest.sentry.io/6095281",
  environment: environment.production ? "production" : "development",
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ["localhost", "https://yourserver.io/api"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
