import { Component, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import { ToastService } from "./@services/toast/toast.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private messageService: MessageService,
    private toastService: ToastService
  ) {}

  toast() {
    this.toastService.getToast().subscribe((config) => {
      this.messageService.add(config);
    });
  }

  ngOnInit() {
    this.toast();
  }
}
