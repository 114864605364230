import { ErrorHandler, Injectable } from "@angular/core";
import { ToastService } from "../toast/toast.service";
import { severity } from "../../@enums/toast.enum";
import { Router } from "@angular/router";

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private toast: ToastService, private router: Router) {}

  handleError(error: any): void {
    this.errorRedirect(error);
  }

  errorRedirect(error: any) {
    const status = error?.status;

    switch (status) {
      case 401:
        this.router
          .navigate(["auth"], {
            queryParamsHandling: null,
          })
          .then();
        this.toast.setToast({
          severity: severity.warn,
          summary: "Unauthorized",
          detail: "Unauthorized",
        });
        break;
      case 505:
        this.toast.setToast({
          severity: severity.error,
          summary: "Error",
          detail: "Server error",
        });
        break;
      default:
        this.toast.setToast({
          severity: severity.error,
          summary: "Error",
          detail: error.message,
        });
    }
  }
}
