import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { TokenProviderService } from "../../@services/token-service/token-provider.service";

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private tokenService: TokenProviderService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const userToken = this.tokenService.token;
    const req = request.clone({
      reportProgress: true,
      headers: request.headers.set("Authorization", `Bearer ${userToken}`),
    });

    return next.handle(req);
  }
}
