import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { severity } from "../../@enums/toast.enum";

interface ToastInterface {
  severity: severity;
  summary: string;
  detail: string;
}

@Injectable({
  providedIn: "root",
})
export class ToastService {
  private toast: Subject<ToastInterface> = new Subject<ToastInterface>();

  constructor() {}

  setToast(config: ToastInterface) {
    this.toast.next(config);
  }

  getToast(): Subject<ToastInterface> {
    return this.toast;
  }
}
